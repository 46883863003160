body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code { */
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
/* } */

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.row-center {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.row-center-space-between {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

h3 {
  /* font-family: 'Courier New', Courier, monospace; */
  font-weight: lighter;
  font-size: 20px;
}

h3.main:hover {
  transition: all 0.3s linear;
  /* text-shadow: 2px 2px 4px #61dafb; */
  -webkit-animation: color-change 1s linear 0s infinite;
          animation: color-change 1s linear 0s infinite;
}

h2 {
  font-weight: lighter;
  font-size: 25px;
  transition: all 1s linear;
}
h2.main:hover {
  transition: all 0.3s linear;
  /* text-shadow: 2px 2px 4px #61dafb; */
  -webkit-animation: color-change 2s linear 0s infinite;
          animation: color-change 2s linear 0s infinite;
}

h4 {
  font-weight: lighter;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0px;
}
@-webkit-keyframes color-change {
  0% {
    color: #eb8888;
  }
  10% {
    color: #ff9d00;
  }
  20% {
    color: #fff300;
  }
  30% {
    color: #d8ff00;
  }
  40% {
    color: #7afe81;
  }
  50% {
    color: #36fe92;
  }
  60% {
    color: #36fed7;
  }
  70% {
    color: #36dbfe;
  }
  80% {
    color: #36abfe;
  }
  90% {
    color: #6b74fc;
  }
  100% {
    color: #fc6bf6;
  }
}
@keyframes color-change {
  0% {
    color: #eb8888;
  }
  10% {
    color: #ff9d00;
  }
  20% {
    color: #fff300;
  }
  30% {
    color: #d8ff00;
  }
  40% {
    color: #7afe81;
  }
  50% {
    color: #36fe92;
  }
  60% {
    color: #36fed7;
  }
  70% {
    color: #36dbfe;
  }
  80% {
    color: #36abfe;
  }
  90% {
    color: #6b74fc;
  }
  100% {
    color: #fc6bf6;
  }
}
div.subtitle {
  font-size: 14px;
  font-weight: lighter;
}
.clickable {
  cursor: pointer;
}

div.separator {
  margin-top: 10px;
  height: 5px;
  padding-left: 13%;
  padding-right: 13%;
  -webkit-animation: bk-color-change 8s linear 0s infinite;
          animation: bk-color-change 8s linear 0s infinite;
  box-sizing: border-box;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

a {
  color: #0097a7;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}

li {
  font-weight: lighter;
  color: #446f7a;
}

blockquote {
  background-color: #fff8e1;
}
p {
  font-weight: lighter;
  font-size: 15px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 5px;
  line-height: 20px;
}

button {
  border-width: 0px;
  background-color: transparent;
  font-size: 16px;
  cursor: pointer;
  font-weight: lighter;
  /* color: #26c6da; */
  /* transition: all 1s linear; */
}
button:hover {
  text-shadow: 2px 2px 10px white;
  -webkit-animation: color-change 1s linear 0s infinite;
          animation: color-change 1s linear 0s infinite;
}

@-webkit-keyframes bk-color-change {
  0% {
    background-color: #eb8888;
  }
  10% {
    background-color: #ff9d00;
  }
  20% {
    background-color: #fff300;
  }
  30% {
    background-color: #d8ff00;
  }
  40% {
    background-color: #7afe81;
  }
  50% {
    background-color: #36fe92;
  }
  60% {
    background-color: #36fed7;
  }
  70% {
    background-color: #36dbfe;
  }
  80% {
    background-color: #36abfe;
  }
  90% {
    background-color: #6b74fc;
  }
  100% {
    background-color: #fc6bf6;
  }
}

@keyframes bk-color-change {
  0% {
    background-color: #eb8888;
  }
  10% {
    background-color: #ff9d00;
  }
  20% {
    background-color: #fff300;
  }
  30% {
    background-color: #d8ff00;
  }
  40% {
    background-color: #7afe81;
  }
  50% {
    background-color: #36fe92;
  }
  60% {
    background-color: #36fed7;
  }
  70% {
    background-color: #36dbfe;
  }
  80% {
    background-color: #36abfe;
  }
  90% {
    background-color: #6b74fc;
  }
  100% {
    background-color: #fc6bf6;
  }
}

