.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.row-center {
  display: flex;
  flex-direction: row;
}

.row-center-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

h3 {
  /* font-family: 'Courier New', Courier, monospace; */
  font-weight: lighter;
  font-size: 20px;
}

h3.main:hover {
  transition: all 0.3s linear;
  /* text-shadow: 2px 2px 4px #61dafb; */
  animation: color-change 1s linear 0s infinite;
}

h2 {
  font-weight: lighter;
  font-size: 25px;
  transition: all 1s linear;
}
h2.main:hover {
  transition: all 0.3s linear;
  /* text-shadow: 2px 2px 4px #61dafb; */
  animation: color-change 2s linear 0s infinite;
}

h4 {
  font-weight: lighter;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0px;
}
@keyframes color-change {
  0% {
    color: #eb8888;
  }
  10% {
    color: #ff9d00;
  }
  20% {
    color: #fff300;
  }
  30% {
    color: #d8ff00;
  }
  40% {
    color: #7afe81;
  }
  50% {
    color: #36fe92;
  }
  60% {
    color: #36fed7;
  }
  70% {
    color: #36dbfe;
  }
  80% {
    color: #36abfe;
  }
  90% {
    color: #6b74fc;
  }
  100% {
    color: #fc6bf6;
  }
}
div.subtitle {
  font-size: 14px;
  font-weight: lighter;
}
.clickable {
  cursor: pointer;
}

div.separator {
  margin-top: 10px;
  height: 5px;
  padding-left: 13%;
  padding-right: 13%;
  animation: bk-color-change 8s linear 0s infinite;
  box-sizing: border-box;
  position: sticky;
  top: 0;
}

a {
  color: #0097a7;
  text-decoration-line: none;
}

li {
  font-weight: lighter;
  color: #446f7a;
}

blockquote {
  background-color: #fff8e1;
}
p {
  font-weight: lighter;
  font-size: 15px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 5px;
  line-height: 20px;
}

button {
  border-width: 0px;
  background-color: transparent;
  font-size: 16px;
  cursor: pointer;
  font-weight: lighter;
  /* color: #26c6da; */
  /* transition: all 1s linear; */
}
button:hover {
  text-shadow: 2px 2px 10px white;
  animation: color-change 1s linear 0s infinite;
}

@keyframes bk-color-change {
  0% {
    background-color: #eb8888;
  }
  10% {
    background-color: #ff9d00;
  }
  20% {
    background-color: #fff300;
  }
  30% {
    background-color: #d8ff00;
  }
  40% {
    background-color: #7afe81;
  }
  50% {
    background-color: #36fe92;
  }
  60% {
    background-color: #36fed7;
  }
  70% {
    background-color: #36dbfe;
  }
  80% {
    background-color: #36abfe;
  }
  90% {
    background-color: #6b74fc;
  }
  100% {
    background-color: #fc6bf6;
  }
}
